import React from "react";
import style from "../modal/Modal.module.css";
import Cookies from "js-cookie";
export const Modal = (props) => {
  const handelSubmit = async (e) => {
    e.preventDefault();
    let token = Cookies.get("auth");
    let res = await fetch(`https://smv.onrender.com/form`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    });
    if (res.status == 200) {
      window.location.replace("/temporayforms");
    }
  };
//   console.log(props);
  return (
    <div>
      <div className={style.test}>
        <div className={style.content}>
          <h4 style={{ marginLeft: "35px" }}>
            {" "}
            هل انت متاكد من اتخاذ هذا الاجراء؟
          </h4>
          <div className={style.btns}>
            <button
              style={{ background: "black" }}
              onClick={() => {
                props.setClk(false);
              }}
            >
              الغاء
            </button>
            <button onClick={handelSubmit} style={{ border: "none" }}>
              {" "}
              حذف{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
